.footer-wrapper {
    background-color: #f5f5f7;
    color: #86868b;
    font-size: 12px;
}

.footer-wrapper ul {
    list-style: none;
    padding-bottom: 10px;
    padding-inline-start: 0px;
}

.footer-wrapper ul a {
    color: #515154;
}



.copyright {
    margin-top: 5px;
    font-size: 15px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    text-align: center;
    height: 50px; 
    width: 100%; 
}

.footer-country {
    margin-top: 8px;
}

.flag-wrapper {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
}

.footer-country-name {
    display: inline-block;
    vertical-align: middle;
}

.footer-links-terms ul {
    display: flex;
}

.footer-links-terms ul li {
    padding: 0 10px;
    border-right: 1px solid #d2d2d7;
}

.footer-links-terms ul li:first-child {
    padding-left: 0;
}

.footer-links-terms ul li:last-child {
    border-right: 0;
}

.footer-links-wrapper h3 {
    font-size: 13px;
    font-weight: 600;
    color: #1d1d1f;
}

.footer-wrapper ul li {
    padding: 4px 0;
}


@media (max-width: 768px) {
    .footer-links-wrapper ul {
        display: none;
    }

    .footer-links-wrapper h3 {
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
    }

    .footer-links-wrapper h3:after {
        font-family: "FontAwesome";
        content: "\f067";
        padding-left: 10px;
        position: absolute;
        right: 25px;
    }
}

.copyright-wrapper {
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding: 5px; 
    width: 100%;
}

.footer-links-terms ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    justify-content: center; 
}

.footer-links-terms ul li {
    padding: 0 10px;
    border-right: 1px solid #d2d2d7; 
}

.footer-links-terms ul li:last-child {
    border-right: none;
}

.footer-links-terms a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
}

.footer-links-terms a:hover {
    color: red; 
}

.audiogram-wrapper {
    text-align: center; 
    padding: 10px 5px; 
    font-size: 16px;
    line-height: 1.5; 
    color: #333;
}

.audiogram-wrapper a {
    color: #1ab59e;
    text-decoration: none;
    font-weight: bold;
}

.audiogram-wrapper a:hover {
    text-decoration: underline;
}

.phone-number {
    font-weight: bold;
    color: #1ab59e;
}


