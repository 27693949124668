.first-highlight-wrapper {
    position: relative;
    height: 580px; 
    overflow: hidden;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}

#bg-video {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.content {
    position: relative;
    z-index: 1;
    padding: 65px 20px;
}

.ipod-caption {
    color: #86868b;
    font-size: 12px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;       
    padding-top: 310px;
}

/* Media Query */
@media (min-width: 768px) {
    .first-highlight-wrapper {
        height: 692px;
    }

    .ipod-caption {
        padding-top: 415px;
    }
}

@media screen and (max-width: 768px) {
    #bg-video {
        width: auto;
        height: auto;
        top: 28npm%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
