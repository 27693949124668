.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding-top: 80px; 
  padding-bottom: 0;

}

.romania-map {
  width: 100%;
  height: auto;
}

.tooltip {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transform: translateY(-20px);
  opacity: 0; 
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; 
}

.tooltip-enter {
  opacity: 1; 
  transform: translateY(0); 
}

.county {
  fill: #ccc; 
  stroke: #333; 
  cursor: pointer;
  transition: fill 0.3s;
}

.county:hover {
  fill: #1ab59e; 
}

#RO-RO {
  pointer-events: none; 
  fill: #666; 
}

.container-harta {
  display: flex;
  flex-direction: column; 
  gap: 20px; 
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 54px;
}

.left-content,
.right-content {
  width: 100%;
}

@media (min-width: 768px) {
  .container-harta {
    flex-direction: row; 
    align-items: flex-start;
  }

  .left-content {
    width: 75%; 
  }

  .right-content {
    width: 25%; 
  }
}


/* Stilul cardului */
.location-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 8px;
  width: 100%;
  max-width: 1200px; 
}


.location-content {
  display: flex; 
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.location-details {
  flex: 1; 
}

.location-type {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 5px;
}

.location-type.clinic {
  background-color: #ccc;
  color: #1ab59e; 
}

.location-type.partner {
  background-color: #bbf7d0; 
  color: #065f46; 
}

.location-title {
  font-size: 27px;
  font-weight: bold;
  color: #1f2937;
}

.location-info {
  display: flex;
  align-items: center;
  color: #4b5563;
  margin-top: 4px;
}


.icon {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}


.location-phone {
  font-size: 20px;
  text-decoration: none;
  color: #1ab59e;
  font-weight: bold;
}

.location-phone:hover {
  color: #1ab59e;
}

.location-address{
  font-size: 20px;
  text-decoration: none;
  color: #1ab59e;
  font-weight: bold;
}

.map-container {
  width: 100px;
  height: 200px;
  flex-shrink: 0; 
}

.map-container iframe {
  width: 100px;
  height: 100%;
  border-radius: 5px;
  border: none;
}

.location-default{
  font-size: 18px;
}