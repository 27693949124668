.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Pentru un fundal semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: auto;
    max-width: 45%; /* Ajustați această valoare după preferințele dvs. */
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column; /* Setăm direcția flexibilă pe coloană pentru a putea utiliza margin: 0 auto */
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Această linie va centra modalul pe orizontală */
}
.modal-close {
    position: absolute;
    top: 0px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 15px;
    color: #333;
}



