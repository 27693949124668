.second-hightlight-wrapper {
	background-image: url("../../../images/home/invizibilul-1.png");
	height: 580px;
	background-position: bottom;
	background-repeat: no-repeat;
  	background-size: initial;
  	background-color: #fbfbfd;
	text-align: center;
	padding: 65px 20px;
	color: #fff;
	margin-bottom: 10px;	
}
@media (min-width: 768px) {
.second-hightlight-wrapper {
    min-height: 692px;
    background-image: url("../../../images/home/invizibilul-1.png");
}
}